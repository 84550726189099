import React, { useState } from 'react';
import { Menu, X, CircuitBoard, User, Wrench, Server, ShoppingCart, LogOut } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import CartButton from './CartButton';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { user, signOut } = useAuthStore();

  const handleForceLogout = async () => {
    if (window.confirm('Are you sure you want to log out from all devices?')) {
      await signOut(true); // Pass true to indicate force logout
      navigate('/login');
    }
  };

  return (
    <nav className="fixed w-full bg-black/90 backdrop-blur-sm z-50 border-b border-white/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <CircuitBoard className="h-8 w-8 text-blue-500" />
              <span className="ml-2 text-xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
                Euronet Trading
              </span>
            </Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/" className="hover:text-blue-500 px-3 py-2 transition-colors">Home</Link>
            <Link to="/trade-in" className="hover:text-blue-500 px-3 py-2 transition-colors">Trade-In</Link>
            <Link to="/products" className="hover:text-blue-500 px-3 py-2 transition-colors">Products</Link>
            <Link to="/hosting" className="hover:text-blue-500 px-3 py-2 transition-colors flex items-center">
              <Server className="h-4 w-4 mr-1" />
              Hosting
            </Link>
            <Link to="/repair" className="hover:text-blue-500 px-3 py-2 transition-colors flex items-center">
              <Wrench className="h-4 w-4 mr-1" />
              Repair
            </Link>
            <Link to="/about" className="hover:text-blue-500 px-3 py-2 transition-colors">About</Link>
            <Link to="/contact" className="hover:text-blue-500 px-3 py-2 transition-colors">Contact</Link>
            
            <div className="flex items-center space-x-2">
              <CartButton />
              <div className="flex items-center space-x-2">
                <Link 
                  to="/dashboard" 
                  className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors flex items-center"
                >
                  <User className="h-4 w-4 mr-2" />
                  Dashboard
                </Link>
                {user && (
                  <button
                    onClick={handleForceLogout}
                    className="p-2 text-red-500 hover:bg-red-500/10 rounded-lg transition-colors"
                    title="Force logout from all devices"
                  >
                    <LogOut className="h-4 w-4" />
                  </button>
                )}
              </div>
            </div>
          </div>
          
          <div className="md:hidden flex items-center space-x-4">
            <CartButton />
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/" className="block px-3 py-2 hover:text-blue-500 transition-colors">Home</Link>
            <Link to="/trade-in" className="block px-3 py-2 hover:text-blue-500 transition-colors">Trade-In</Link>
            <Link to="/products" className="block px-3 py-2 hover:text-blue-500 transition-colors">Products</Link>
            <Link to="/hosting" className="block px-3 py-2 hover:text-blue-500 transition-colors flex items-center">
              <Server className="h-4 w-4 mr-1" />
              Hosting
            </Link>
            <Link to="/repair" className="block px-3 py-2 hover:text-blue-500 transition-colors flex items-center">
              <Wrench className="h-4 w-4 mr-1" />
              Repair
            </Link>
            <Link to="/about" className="block px-3 py-2 hover:text-blue-500 transition-colors">About</Link>
            <Link to="/contact" className="block px-3 py-2 hover:text-blue-500 transition-colors">Contact</Link>
            <Link 
              to="/dashboard" 
              className="block w-full text-left bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors flex items-center"
            >
              <User className="h-4 w-4 mr-2" />
              Dashboard
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;