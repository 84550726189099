import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Wrench, ArrowRight, CircuitBoard, Cpu, Battery } from 'lucide-react';
import SEOTags from '../components/SEOTags';
import Hero from '../components/Hero';
import Features from '../components/Features';
import TradeInCalculator from '../components/TradeInCalculator';

const HomePage = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Euronet Trading",
    "url": "https://euronet-trading.co.uk",
    "logo": "https://euronet-trading.co.uk/logo.png",
    "description": "Europe's leading platform for trading cryptocurrency mining hardware. Buy, sell, and trade Bitcoin miners, ASIC miners, and mining equipment.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "275 New North Road",
      "addressLocality": "London",
      "postalCode": "N1 7AA",
      "addressCountry": "GB"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+44-20-1234-5678",
      "contactType": "customer service",
      "email": "info@euronet-trading.co.uk"
    }
  };
  return (
    <>
      <SEOTags
        title="Euronet Trading | Cryptocurrency Mining Hardware Trading Platform"
        description="Europe's leading platform for trading cryptocurrency mining hardware. Buy, sell, and trade Bitcoin miners, ASIC miners, and mining equipment. Best trade-in values guaranteed."
        keywords="crypto mining, bitcoin miner, antminer, whatsminer, asic miner, mining hardware, crypto trading, mining equipment, bitcoin mining"
        canonicalUrl="https://euronet-trading.co.uk"
        structuredData={structuredData}
      />
      <Hero />
      <Features />
      
      {/* Repair Services Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center bg-blue-500/10 px-4 py-2 rounded-full mb-4"
          >
            <Wrench className="h-5 w-5 text-blue-500 mr-2" />
            <span className="text-blue-500">Professional Repair Center</span>
          </motion.div>
          <h2 className="text-3xl font-bold mb-4">Expert Repair Services</h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Specialized repair services for Bitmain mining hardware
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10 hover:border-blue-500/50 transition-all"
          >
            <div className="bg-blue-500/10 p-4 rounded-full w-fit mb-4">
              <CircuitBoard className="h-8 w-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Hashboard Repair</h3>
            <p className="text-gray-400 mb-4">Expert repair of Bitmain hashboards, including chip replacement and PCB repair</p>
            <p className="text-lg font-semibold text-blue-500">Starting from €150</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10 hover:border-blue-500/50 transition-all"
          >
            <div className="bg-blue-500/10 p-4 rounded-full w-fit mb-4">
              <Cpu className="h-8 w-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Control Board Repair</h3>
            <p className="text-gray-400 mb-4">Control board diagnostics and component-level repair services</p>
            <p className="text-lg font-semibold text-blue-500">Starting from €100</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10 hover:border-blue-500/50 transition-all"
          >
            <div className="bg-blue-500/10 p-4 rounded-full w-fit mb-4">
              <Battery className="h-8 w-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Power Supply Repair</h3>
            <p className="text-gray-400 mb-4">PSU repair and maintenance for all Antminer models</p>
            <p className="text-lg font-semibold text-blue-500">Starting from €80</p>
          </motion.div>
        </div>

        <div className="text-center">
          <Link
            to="/repair"
            className="inline-flex items-center bg-blue-600 hover:bg-blue-700 px-8 py-4 rounded-lg text-lg font-semibold transition-all transform hover:scale-105"
          >
            View Repair Services
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>

      <TradeInCalculator />
    </>
  );
};

export default HomePage;