import { useQuery } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';

export const useDashboardData = () => {
  const getOrders = async () => {
    const { data, error } = await supabase
      .from('orders')
      .select(`
        *,
        items:order_items(
          *,
          product:products(*)
        )
      `)
      .order('created_at', { ascending: false });
    if (error) throw error;
    return data;
  };

  const getRepairRequests = async () => {
    const { data, error } = await supabase
      .from('repair_requests')
      .select(`
        id,
        device_type,
        model,
        serial_number,
        status,
        created_at,
        updated_at
      `)
      .order('created_at', { ascending: false });
    if (error) throw error;
    return data;
  };

  const getTradeIns = async () => {
    const { data, error } = await supabase
      .from('trade_in_requests')
      .select(`
        id,
        miner_model,
        serial_number,
        condition,
        status,
        created_at,
        updated_at
      `)
      .order('created_at', { ascending: false });
    if (error) throw error;
    return data;
  };

  const getUserProfile = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return null;

    const { data, error } = await supabase
      .from('users')
      .select(`
        id,
        email,
        full_name,
        phone,
        company,
        created_at,
        admin:admin_users!left(is_super_admin)
      `)
      .eq('id', user.id)
      .single();
    
    if (error) throw error;
    
    return {
      ...data,
      is_admin: data.admin !== null,
      is_super_admin: data.admin?.is_super_admin || false
    };
  };

  return {
    orders: useQuery({
      queryKey: ['orders'],
      queryFn: getOrders
    }),
    repairs: useQuery({
      queryKey: ['repairs'],
      queryFn: getRepairRequests
    }),
    tradeIns: useQuery({
      queryKey: ['trade-ins'],
      queryFn: getTradeIns
    }),
    profile: useQuery({
      queryKey: ['user-profile'],
      queryFn: getUserProfile
    })
  };
};