import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductDetails from '../components/ProductDetails';
import ProductSEO from '../components/ProductSEO';
import { useProducts } from '../hooks/useProducts';
import { Product } from '../data/types';

const ProductPage = () => {
  const { category, id } = useParams();
  const { data: products, isLoading } = useProducts();
  const [showDetails, setShowDetails] = useState(true);
  
  const productId = parseInt(id?.split('-')[0] || '0', 10);
  const product = products?.find(p => p.id === productId);

  if (isLoading) {
    return (
      <div className="min-h-screen pt-24 pb-16 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="min-h-screen pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">Product Not Found</h1>
            <p className="text-gray-400">The product you're looking for doesn't exist or has been removed.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-24 pb-16">
      <ProductSEO product={product} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {showDetails && (
          <ProductDetails 
            product={product} 
            onClose={() => setShowDetails(false)} 
          />
        )}
      </div>
    </div>
  );
};

export default ProductPage;