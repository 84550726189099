import React from 'react';
import { motion } from 'framer-motion';
import { X, Zap, Activity, Server, Droplets, Package, Cpu, Battery, Gauge, Network, Clock } from 'lucide-react';
import ShareButtons from './ShareButtons';
import { Product } from '../data/types';
import { formatPrice } from '../utils/priceFormatter';
import { STORAGE_BUCKETS } from '../lib/supabase';
import { useSupabaseImage } from '../hooks/useSupabaseImage';
import { useCartStore } from '../stores/cartStore';

interface ProductDetailsProps {
  product: Product;
  onClose: () => void;
}

const ProductDetails = ({ product, onClose }: ProductDetailsProps) => {
  const imageUrl = useSupabaseImage(STORAGE_BUCKETS.PRODUCTS, product.image);
  const addItem = useCartStore(state => state.addItem);

  const handleAddToCart = () => {
    addItem(product, 'standard');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-gradient-to-b from-blue-900/40 to-black/40 rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto border border-white/10"
      >
        <div className="sticky top-0 bg-black/90 backdrop-blur-sm p-6 border-b border-white/10 flex justify-between items-center">
          <h2 className="text-2xl font-bold">{product.name}</h2>
          <div className="flex items-center gap-4">
            <ShareButtons
              url={`${window.location.origin}/products/${product.category.toLowerCase().replace(/\s+/g, '-')}/${product.id}-${product.name.toLowerCase().replace(/\s+/g, '-')}`}
              title={`${product.name} - ${product.hashrate} Mining Hardware`}
              description={`${product.name} with ${product.hashrate} hashrate for ${product.algorithm} mining. Available now at Euronet Trading.`}
            />
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-lg transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
          </div>
        </div>

        <div className="p-6">
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div className="space-y-6">
              <div className="relative h-64 rounded-xl overflow-hidden">
                <img
                  src={imageUrl || 'https://images.unsplash.com/photo-1640079421264-61f9d6f3efb1?auto=format&fit=crop&q=80&w=600&h=400'}
                  alt={product.name}
                  className="w-full h-full object-cover"
                />
                {product.stock && (
                  <div className="absolute top-4 right-4 bg-blue-600 px-3 py-1 rounded-full text-sm">
                    {product.stock}
                  </div>
                )}
              </div>
              
              <div className="bg-black/40 rounded-xl p-6">
                <div className="text-3xl font-bold text-blue-500 mb-4">
                  {formatPrice(product.price)}
                  {product.last_price_update && (
                    <div className="text-sm font-normal text-gray-400 mt-1">
                      Price updated: {new Date(product.last_price_update).toLocaleString()}
                    </div>
                  )}
                </div>
                <button 
                  onClick={handleAddToCart}
                  className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg transition-all flex items-center justify-center space-x-2"
                >
                  <Package className="h-5 w-5" />
                  <span>Add to Cart</span>
                </button>
              </div>
            </div>

            <div className="space-y-6">
              <div className="bg-black/40 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-4">Performance Specs</h3>
                <div className="space-y-4">
                  <div className="flex items-center text-gray-400">
                    <Zap className="h-5 w-5 mr-3 text-blue-500" />
                    <span className="w-32">Hashrate:</span>
                    <span className="font-semibold text-white">{product.hashrate}</span>
                  </div>
                  {product.release_date && (
                    <div className="flex items-center text-gray-400">
                      <Clock className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Release Date:</span>
                      <span className="font-semibold text-white">{product.release_date}</span>
                    </div>
                  )}
                  {product.efficiency && (
                    <div className="flex items-center text-gray-400">
                      <Activity className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Efficiency:</span>
                      <span className="font-semibold text-white">{product.efficiency}</span>
                    </div>
                  )}
                  <div className="flex items-center text-gray-400">
                    <Server className="h-5 w-5 mr-3 text-blue-500" />
                    <span className="w-32">Algorithm:</span>
                    <span className="font-semibold text-white">{product.algorithm}</span>
                  </div>
                  {product.power_consumption && (
                    <div className="flex items-center text-gray-400">
                      <Battery className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Power:</span>
                      <span className="font-semibold text-white">{product.power_consumption}</span>
                    </div>
                  )}
                  {product.cooling && (
                    <div className="flex items-center text-gray-400">
                      <Droplets className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Cooling:</span>
                      <span className="font-semibold text-white">{product.cooling}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="bg-black/40 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-4">Hardware Specs</h3>
                <div className="space-y-4">
                  {product.dimensions && (
                    <div className="flex items-center text-gray-400">
                      <Package className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Dimensions:</span>
                      <span className="font-semibold text-white">{product.dimensions}</span>
                    </div>
                  )}
                  {product.weight && (
                    <div className="flex items-center text-gray-400">
                      <Package className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Weight:</span>
                      <span className="font-semibold text-white">{product.weight}</span>
                    </div>
                  )}
                  {product.noise_level && (
                    <div className="flex items-center text-gray-400">
                      <Gauge className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Noise Level:</span>
                      <span className="font-semibold text-white">{product.noise_level}</span>
                    </div>
                  )}
                  {product.network_interface && (
                    <div className="flex items-center text-gray-400">
                      <Network className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Interface:</span>
                      <span className="font-semibold text-white">{product.network_interface}</span>
                    </div>
                  )}
                  {product.chip_count && (
                    <div className="flex items-center text-gray-400">
                      <Cpu className="h-5 w-5 mr-3 text-blue-500" />
                      <span className="w-32">Chip Count:</span>
                      <span className="font-semibold text-white">{product.chip_count}</span>
                    </div>
                  )}
                </div>
              </div>

              {product.warranty && (
                <div className="bg-black/40 rounded-xl p-6">
                  <h3 className="text-xl font-semibold mb-2">Warranty</h3>
                  <p className="text-gray-400">{product.warranty}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ProductDetails;