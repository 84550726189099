import { create } from 'zustand';
import { User, Session } from '@supabase/supabase-js';
import { supabase, signIn, signUp, signOut, resetPassword, updateUserProfile } from '../lib/supabase';

interface AuthState {
  user: User | null;
  session: Session | null;
  loading: boolean;
  setUser: (user: User | null) => void;
  setSession: (session: Session | null) => void;
  setLoading: (loading: boolean) => void;
  signIn: (email: string, password: string) => Promise<void>; 
  signUp: (email: string, password: string, userData: any) => Promise<void>;
  signOut: (force?: boolean) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateProfile: (data: any) => Promise<void>;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  user: null,
  session: null,
  loading: true,

  setUser: (user) => set({ user }),
  setSession: (session) => set({ session }),
  setLoading: (loading) => set({ loading }),

  signIn: async (email: string, password: string) => {
    const { user, session } = await signIn(email, password);
    set({ user, session });
  },

  signUp: async (email: string, password: string, userData: any) => {
    const { user, session } = await signUp(email, password, userData);
    set({ user, session });
  },

  signOut: async (force = false) => {
    if (force) {
      // Sign out from all devices
      await supabase.auth.signOut({ scope: 'global' });
    } else {
      // Sign out from current device only
      await supabase.auth.signOut();
    }
    set({ user: null, session: null });
  },

  resetPassword: async (email: string) => {
    await resetPassword(email);
  },

  updateProfile: async (data: any) => {
    const { user } = get();
    if (!user) throw new Error('No user logged in');

    await updateUserProfile(user.id, data);
    
    const { data: updatedUser, error } = await supabase.auth.updateUser({
      data: { ...user.user_metadata, ...data }
    });

    if (error) throw error;
    set({ user: updatedUser.user });
  }
}));

// Initialize auth state
supabase.auth.onAuthStateChange((event, session) => {
  useAuthStore.setState({
    user: session?.user || null,
    session,
    loading: false
  });
});