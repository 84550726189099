import React from 'react';
import { Search, SlidersHorizontal } from 'lucide-react';

interface ProductSearchProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
  sortBy: string;
  onSortChange: (sort: string) => void;
  priceRange: { min: number; max: number };
  onPriceRangeChange: (range: { min: number; max: number }) => void;
}

const ProductSearch = ({
  searchQuery,
  onSearchChange,
  sortBy,
  onSortChange,
  priceRange,
  onPriceRangeChange
}: ProductSearchProps) => {
  return (
    <div className="mb-8 space-y-4">
      <div className="flex flex-wrap gap-4">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search miners by name, algorithm, or hashrate..."
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
              className="w-full bg-black/50 border border-white/10 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
        
        <div className="flex gap-4">
          <div className="relative">
            <SlidersHorizontal className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <select
              value={sortBy}
              onChange={(e) => onSortChange(e.target.value)}
              className="bg-black/50 border border-white/10 rounded-lg pl-10 pr-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none"
            >
              <option value="name">Name (A-Z)</option>
              <option value="-name">Name (Z-A)</option>
              <option value="price">Price (Low to High)</option>
              <option value="-price">Price (High to Low)</option>
              <option value="hashrate">Hashrate (Low to High)</option>
              <option value="-hashrate">Hashrate (High to Low)</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex gap-4 items-center">
        <span className="text-gray-400">Price Range:</span>
        <input
          type="number"
          placeholder="Min"
          value={priceRange.min || ''}
          onChange={(e) => onPriceRangeChange({ ...priceRange, min: Number(e.target.value) })}
          className="w-24 bg-black/50 border border-white/10 rounded-lg px-3 py-1 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <span className="text-gray-400">-</span>
        <input
          type="number"
          placeholder="Max"
          value={priceRange.max || ''}
          onChange={(e) => onPriceRangeChange({ ...priceRange, max: Number(e.target.value) })}
          className="w-24 bg-black/50 border border-white/10 rounded-lg px-3 py-1 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>
    </div>
  );
};

export default ProductSearch;