import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  LayoutDashboard,
  ArrowRightLeft,
  Package,
  Settings,
  User,
  LogOut,
  Wrench,
  Plus,
  Bell,
  ShoppingBag
} from 'lucide-react';
import { useAuthStore } from '../stores/authStore';
import { useDashboardData } from '../hooks/useDashboardData';
import RepairStatus from '../components/RepairStatus';

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const navigate = useNavigate();
  const signOut = useAuthStore(state => state.signOut);
  const { orders, repairs, tradeIns, profile } = useDashboardData();

  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };

  const renderOverview = () => {
    if (orders.isLoading || repairs.isLoading || tradeIns.isLoading || profile.isLoading) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    if (profile.error) {
      return (
        <div className="text-center text-red-500">
          Error loading profile. Please try logging in again.
        </div>
      );
    }

    return (
      <div className="space-y-6">
        {/* User Profile Section */}
        <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10 p-6">
          <h2 className="text-xl font-bold mb-4">Welcome, {profile.data?.full_name || 'User'}</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <p className="text-gray-400">Email:</p>
              <p className="font-semibold">{profile.data?.email}</p>
            </div>
            {profile.data?.phone && (
              <div>
                <p className="text-gray-400">Phone:</p>
                <p className="font-semibold">{profile.data.phone}</p>
              </div>
            )}
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-semibold">Active Orders</h3>
              <ShoppingBag className="h-5 w-5 text-blue-500" />
            </div>
            <p className="text-3xl font-bold">{orders.data?.length || 0}</p>
            <p className="text-sm text-gray-400 mt-1">Processing</p>
          </div>

          <div className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-semibold">Repair Requests</h3>
              <Wrench className="h-5 w-5 text-blue-500" />
            </div>
            <p className="text-3xl font-bold">{repairs.data?.length || 0}</p>
            <p className="text-sm text-gray-400 mt-1">In Progress</p>
          </div>

          <div className="bg-gradient-to-b from-blue-900/20 to-transparent p-6 rounded-xl border border-white/10">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-semibold">Trade-Ins</h3>
              <ArrowRightLeft className="h-5 w-5 text-blue-500" />
            </div>
            <p className="text-3xl font-bold">{tradeIns.data?.length || 0}</p>
            <p className="text-sm text-gray-400 mt-1">Pending</p>
          </div>
        </div>

        {orders.data && orders.data.length > 0 && (
          <div className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl border border-white/10">
            <div className="p-6 border-b border-white/10">
              <h3 className="text-lg font-semibold">Recent Orders</h3>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                {orders.data.map(order => (
                  <div key={order.id} className="flex items-start gap-4">
                    <div className="bg-blue-500/10 p-2 rounded-lg">
                      <ShoppingBag className="h-5 w-5 text-blue-500" />
                    </div>
                    <div>
                      <p className="font-semibold">Order #{order.order_number}</p>
                      <p className="text-sm text-gray-400">
                        {order.shipping_status} • €{order.total_amount}
                      </p>
                      <p className="text-xs text-gray-500 mt-1">
                        {new Date(order.created_at).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  // ... rest of the component code ...

  return (
    <div className="min-h-screen bg-black text-white">
      {/* ... existing layout code ... */}
    </div>
  );
};

export default DashboardPage;