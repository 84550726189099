import { Product } from '../data/types';

interface ParsedProduct {
  name: string;
  price: number;
  category: string;
  releaseDate?: string;
}

// Convert USD to EUR (using a fixed rate)
const USD_TO_EUR_RATE = 0.92;
const convertToEUR = (usdPrice: number): number => {
  return Math.round(usdPrice * USD_TO_EUR_RATE);
};

export const parseProductMessage = (message: string): ParsedProduct[] => {
  const products: ParsedProduct[] = [];
  let currentCategory = '';
  
  // Split message into lines and clean them
  const lines = message.split('\n').map(line => line.trim()).filter(Boolean);
  
  for (let line of lines) {
    // Check if line is a category header
    if (line.startsWith('*') && line.endsWith('*')) {
      currentCategory = line.replace(/\*/g, '').trim();
      continue;
    }
    
    // Parse product line with flexible format:
    // Product: $1,234 (info)
    // Product: $1234 (info)
    // Product: $1234 (16.5J/T) Jan.
    const match = line.match(/^([^:]+):\s*\$(\d+(?:,\d{3})*)\s*(?:\(([\d.]+[A-Za-z]\/[A-Za-z])\))?\s*(.+)?$/);
    
    if (match) {
      const [, name, priceStr, efficiency, dateStr] = match;
      
      // Convert price string to number, removing commas
      const usdPrice = parseFloat(priceStr.replace(/,/g, ''));
      
      // Convert USD to EUR
      const price = convertToEUR(usdPrice);
      
      let releaseDate = undefined;

      // Parse date strings
      if (dateStr) {
        releaseDate = dateStr.trim();
      }
      
      products.push({
        name: name.trim(),
        price,
        category: currentCategory,
        releaseDate
      });
    }
  }
  
  return products;
};

export const applyDiscount = (price: number, discountPercent: number = 0.5): number => {
  return Math.round(price * (1 - discountPercent / 100));
};