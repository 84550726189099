import React, { useState } from 'react';
import { useProducts } from '../hooks/useProducts';
import { useCategories } from '../hooks/useCategories';
import ProductCard from '../components/ProductCard';
import ProductSearch from '../components/ProductSearch';
import ProductDetails from '../components/ProductDetails';
import SEOTags from '../components/SEOTags';
import { Product } from '../data/types';

const ProductsPage = () => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<string>('price');
  const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
  const { data: products, isLoading: productsLoading } = useProducts(selectedCategory);
  const { categories } = useCategories();

  const filteredAndSortedProducts = React.useMemo(() => {
    if (!products) return [];

    const filtered = products.filter(product => {
      const matchesSearch = searchQuery.toLowerCase().split(' ').every(term =>
        product.name.toLowerCase().includes(term) ||
        product.algorithm.toLowerCase().includes(term) ||
        product.hashrate.toLowerCase().includes(term)
      );

      const matchesPrice = (!priceRange.min || product.price >= priceRange.min) &&
                          (!priceRange.max || product.price <= priceRange.max);

      return matchesSearch && matchesPrice;
    });

    return filtered.sort((a, b) => {
      const [field, order] = sortBy.startsWith('-') 
        ? [sortBy.slice(1), -1] 
        : [sortBy, 1];

      switch (field) {
        case 'name':
          return order * a.name.localeCompare(b.name);
        case 'price':
          return order * (a.price - b.price);
        case 'hashrate': {
          const getHashrateValue = (str: string) => {
            const match = str.match(/(\d+(?:\.\d+)?)/);
            return match ? parseFloat(match[1]) : 0;
          };
          return order * (getHashrateValue(a.hashrate) - getHashrateValue(b.hashrate));
        }
        default:
          return 0;
      }
    });
  }, [products, searchQuery, sortBy, priceRange]);

  // Generate structured data for products
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": products?.map((product, index) => ({
      "@type": "Product",
      "position": index + 1,
      "name": product.name,
      "description": `${product.name} - ${product.hashrate} ${product.algorithm} Miner`,
      "image": product.image,
      "offers": {
        "@type": "Offer",
        "price": product.price,
        "priceCurrency": "EUR",
        "availability": product.stock ? "https://schema.org/InStock" : "https://schema.org/PreOrder"
      }
    }))
  };

  if (productsLoading || categories.isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="pt-24 pb-16">
      <SEOTags
        title="Mining Hardware | Buy Cryptocurrency Miners | Euronet Trading"
        description="Browse our selection of high-performance cryptocurrency mining hardware. We offer the latest ASIC miners, including Bitcoin, Litecoin, and other crypto miners."
        keywords="crypto mining hardware, bitcoin miner, asic miner, cryptocurrency mining equipment, mining rig, antminer, whatsminer"
        canonicalUrl="https://euronet-trading.co.uk/products"
        structuredData={structuredData}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Mining Hardware</h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Browse our selection of high-performance mining equipment
          </p>
        </div>

        <ProductSearch
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          sortBy={sortBy}
          onSortChange={setSortBy}
          priceRange={priceRange}
          onPriceRangeChange={setPriceRange}
        />

        <div className="flex flex-wrap gap-4 mb-12 justify-center">
          <button
            onClick={() => setSelectedCategory(undefined)}
            className={`px-4 py-2 rounded-lg transition-colors ${
              !selectedCategory
                ? 'bg-blue-600'
                : 'bg-black/40 hover:bg-blue-600/20'
            }`}
          >
            All Products
          </button>
          {categories.data?.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-4 py-2 rounded-lg transition-colors ${
                selectedCategory === category.id
                  ? 'bg-blue-600'
                  : 'bg-black/40 hover:bg-blue-600/20'
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {filteredAndSortedProducts.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onClick={() => setSelectedProduct(product)}
            />
          ))}
        </div>

        {selectedProduct && (
          <ProductDetails
            product={selectedProduct}
            onClose={() => setSelectedProduct(null)}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsPage;