import React from 'react';
import { Share2, Facebook, Twitter, Linkedin, Link as LinkIcon } from 'lucide-react';

interface ShareButtonsProps {
  url: string;
  title: string;
  description: string;
}

const ShareButtons = ({ url, title, description }: ShareButtonsProps) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="flex items-center gap-4">
      <span className="flex items-center text-gray-400">
        <Share2 className="h-4 w-4 mr-2" />
        Share:
      </span>
      
      <div className="flex gap-2">
        <a
          href={shareLinks.facebook}
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 hover:bg-blue-600/20 rounded-lg transition-colors text-blue-500"
          title="Share on Facebook"
        >
          <Facebook className="h-5 w-5" />
        </a>

        <a
          href={shareLinks.twitter}
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 hover:bg-blue-400/20 rounded-lg transition-colors text-blue-400"
          title="Share on Twitter"
        >
          <Twitter className="h-5 w-5" />
        </a>

        <a
          href={shareLinks.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 hover:bg-blue-700/20 rounded-lg transition-colors text-blue-700"
          title="Share on LinkedIn"
        >
          <Linkedin className="h-5 w-5" />
        </a>

        <button
          onClick={copyToClipboard}
          className="p-2 hover:bg-gray-500/20 rounded-lg transition-colors text-gray-400"
          title="Copy link"
        >
          <LinkIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default ShareButtons;