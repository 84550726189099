import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Activity, Server } from 'lucide-react';
import { Product } from '../data/types';
import { formatPrice } from '../utils/priceFormatter';
import { STORAGE_BUCKETS } from '../lib/supabase';
import { useSupabaseImage } from '../hooks/useSupabaseImage';

interface ProductCardProps {
  product: Product;
  onClick: () => void;
}

const ProductCard = ({ product, onClick }: ProductCardProps) => {
  const imageUrl = useSupabaseImage(STORAGE_BUCKETS.PRODUCTS, product.image);
  const fallbackImage = 'https://images.unsplash.com/photo-1640079421264-61f9d6f3efb1?auto=format&fit=crop&q=80&w=600&h=400';

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-b from-blue-900/20 to-transparent rounded-xl overflow-hidden border border-white/10 hover:border-blue-500/50 transition-all cursor-pointer group"
      onClick={onClick}
    >
      <div className="relative aspect-[4/3] overflow-hidden">
        <img
          src={imageUrl || fallbackImage}
          alt={product.name}
          className="w-full h-full object-contain bg-black/40 p-4 group-hover:scale-105 transition-transform duration-300"
          onError={(e) => {
            e.currentTarget.src = fallbackImage;
          }}
        />
        {product.stock && (
          <div className="absolute top-2 right-2 bg-blue-600/90 backdrop-blur-sm px-3 py-1 rounded-full text-xs font-medium">
            {product.release_date || product.stock}
          </div>
        )}
      </div>
      
      <div className="p-4">
        <div className="flex items-start justify-between mb-4">
          <div>
            <h3 className="text-base font-semibold line-clamp-2">{product.name}</h3>
            {product.last_price_update && (
              <p className="text-xs text-gray-400">
                Price updated: {new Date(product.last_price_update).toLocaleDateString()}
              </p>
            )}
            <p className="text-xs text-gray-400 mt-1">{product.category}</p>
          </div>
          <div className="text-xl font-bold text-blue-500">
            {formatPrice(product.price)}
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex items-center text-gray-400 text-sm">
            <Zap className="h-4 w-4 mr-2 text-blue-500" />
            <span>{product.hashrate}</span>
          </div>
          {product.efficiency && (
            <div className="flex items-center text-gray-400 text-sm">
              <Activity className="h-4 w-4 mr-2 text-blue-500" />
              <span>{product.efficiency}</span>
            </div>
          )}
          <div className="flex items-center text-gray-400 text-sm">
            <Server className="h-4 w-4 mr-2 text-blue-500" />
            <span>{product.algorithm}</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProductCard;