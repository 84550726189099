import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { DollarSign, RefreshCw, Save, X, AlertTriangle } from 'lucide-react';
import { parseProductMessage, applyDiscount } from '../../utils/priceParser';
import { supabase } from '../../lib/supabase';

const PriceUpdateTool = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [discountPercent, setDiscountPercent] = useState(0.5);

  const handleParse = () => {
    try {
      const parsed = parseProductMessage(message);
      setPreview(parsed.map(product => ({
        ...product,
        originalPrice: product.price,
        price: applyDiscount(product.price, discountPercent)
      })));
      setError('');
    } catch (err) {
      setError('Failed to parse message. Please check the format.');
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    setError('');
    let updatedCount = 0;

    try {
      for (const product of preview) {
        const { error } = await supabase
          .from('products')
          .update({ 
            price: product.price,
            last_price_update: new Date().toISOString(),
            release_date: product.releaseDate || null
          })
          .eq('name', product.name);

        if (error) {
          console.error(`Failed to update ${product.name}:`, error);
          continue;
        }
        updatedCount++;
      }

      if (updatedCount > 0) {
        setMessage('');
        setPreview([]);
        setError(`Successfully updated ${updatedCount} products`);
      } else {
        setError('No products were updated');
      }
    } catch (err) {
      console.error('Update error:', err);
      setError('Failed to update prices. Please check the console for details.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold">Price Update Tool</h2>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <label className="text-sm">Discount:</label>
            <input
              type="number"
              value={discountPercent}
              onChange={(e) => setDiscountPercent(Number(e.target.value))}
              className="w-20 bg-black/50 border border-white/10 rounded-lg px-3 py-1"
              min="0"
              max="100"
              step="0.1"
            />
            <span className="text-sm">%</span>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-500/10 border border-red-500/50 rounded-lg p-4 flex items-start gap-3">
          <AlertTriangle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
          <p className="text-red-400">{error}</p>
        </div>
      )}

      <div className="grid md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium mb-2">Paste Price Message</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full h-[400px] bg-black/50 border border-white/10 rounded-lg px-4 py-2 font-mono text-sm"
            placeholder="Paste the price message here..."
          />
          <button
            onClick={handleParse}
            disabled={!message}
            className="mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <RefreshCw className="h-4 w-4" />
            Parse Message
          </button>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Preview Changes</label>
          <div className="bg-black/50 border border-white/10 rounded-lg h-[400px] overflow-y-auto">
            {preview.length > 0 ? (
              <table className="w-full">
                <thead className="border-b border-white/10">
                  <tr>
                    <th className="text-left p-4">Product</th>
                    <th className="text-right p-4">Original</th>
                    <th className="text-right p-4">New Price</th>
                    <th className="text-right p-4">Release</th>
                  </tr>
                </thead>
                <tbody>
                  {preview.map((product, index) => (
                    <tr key={index} className="border-b border-white/10">
                      <td className="p-4">{product.name}</td>
                      <td className="text-right p-4 text-gray-400">
                        ${product.originalPrice.toLocaleString()}
                      </td>
                      <td className="text-right p-4 text-green-400">
                        ${product.price.toLocaleString()}
                      </td>
                      <td className="text-right p-4 text-gray-400">
                        {product.releaseDate || 'In Stock'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex items-center justify-center h-full text-gray-400">
                Parse a message to preview changes
              </div>
            )}
          </div>
          <button
            onClick={handleUpdate}
            disabled={preview.length === 0 || loading}
            className="mt-4 bg-green-600 hover:bg-green-700 px-4 py-2 rounded-lg transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <>
                <div className="w-4 h-4 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                <span>Updating...</span>
              </>
            ) : (
              <>
                <Save className="h-4 w-4" />
                <span>Update Prices</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PriceUpdateTool;