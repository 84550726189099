import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import { User, UserFormData } from '../data/types';

export const useUsers = () => {
  const queryClient = useQueryClient();

  const getUsers = async () => {
    const { data: users, error } = await supabase
      .from('auth.users')
      .select(`
        id,
        email, 
        raw_user_meta_data->>'full_name' as full_name,
        created_at,
        last_sign_in_at,
        admin:admin_users(is_super_admin)
      `)
      .order('created_at', { ascending: false });

    if (error) throw error;
    
    return (users || []).map(user => ({
      id: user.id,
      email: user.email,
      full_name: user.full_name || '',
      phone: '',
      company: '',
      created_at: user.created_at,
      is_admin: user.admin?.length > 0,
      is_super_admin: user.admin?.[0]?.is_super_admin || false
    })) as User[];
  };

  const updateUser = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: UserFormData }) => {
      // Update user profile in public users table
      const { error: userError } = await supabase
        .from('users')
        .update({
          full_name: data.full_name,
          phone: data.phone,
          company: data.company
        })
        .eq('id', id);

      if (userError) throw userError;

      // Handle admin status
      if (data.is_admin) {
        await supabase
          .from('admin_users')
          .upsert({ user_id: id })
          .eq('user_id', id);
      } else {
        await supabase
          .from('admin_users')
          .delete()
          .eq('user_id', id);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  });

  const deleteUser = useMutation({
    mutationFn: async (id: string) => {
      // Delete from admin_users first (if exists)
      await supabase
        .from('admin_users')
        .delete()
        .eq('user_id', id);

      // Then delete the user
      const { error } = await supabase
        .from('users')
        .delete()
        .eq('id', id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  });

  return {
    users: useQuery({
      queryKey: ['users'],
      queryFn: getUsers
    }),
    updateUser,
    deleteUser
  };
};